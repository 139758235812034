<template>
  <div class="history">
    <div class="gallery">
      <div class="gallery-title">
        <p>{{ $t("message.history-title") }}</p>
      </div>

      <div class="row-1 flex-row">
        <div class="text flex-column">
          <div class="flex-row">
            <p class="year">{{ this.content.history[7].year }}</p>
          </div>
          <p class="content">{{ this.content.history[7]["content-detail"] }}</p>
          <p class="content" style="margin-top: 50%">
            {{ this.content.history[7].content2 }}
          </p>
        </div>
        <div class="pic">
          <img
            style="margin-top: 5%"
            loading="lazy"
            src="../../assets/imgs/home/热带雨林.jpg"
            alt=""
          />
          <img loading="lazy" src="../../assets/imgs/home/hotel.png" alt="" />
        </div>
      </div>

      <div class="row-1 flex-row">
        <div class="text flex-column">
          <div class="flex-row">
            <p class="year">{{ this.content.history[6].year }}</p>
          </div>
          <p class="content">{{ this.content.history[6]["content-detail"] }}</p>
        </div>
        <div class="pic flex-row">
          <img
            style="margin-left: 10%; margin-top: 15%"
            loading="lazy"
            src="../../assets/imgs/home/dangga heights.jpg"
            alt=""
          />
        </div>
      </div>

      <div class="row-1 flex-row">
        <div class="text flex-column">
          <div class="flex-row">
            <p class="year">{{ this.content.history[5].year }}</p>
          </div>
          <p class="content">
            {{ this.content.history[5]["content-2-detail"] }}
          </p>
          <p class="content" style="margin-top: 40%">
            {{ this.content.history[5]["content-1-detail"] }}
          </p>
        </div>
        <div class="pic flex-row">
          <img
            loading="lazy"
            style="margin-top: 20%"
            src="../../assets/imgs/home/poiz_bedok_south.jpg"
            alt=""
          />
          <img
            loading="lazy"
            style="margin-top: 10%"
            src="../../assets/imgs/home/sentorini_canopy.jpg"
            alt=""
          />
        </div>
      </div>

      <div class="row-1 flex-row">
        <div class="text flex-column">
          <div class="flex-row">
            <div class="flex-row">
              <p class="year">{{ this.content.history[4].year }}</p>
            </div>
          </div>

          <p class="content">{{ this.content.history[4]["content-detail"] }}</p>
        </div>
        <div class="pic">
          <img
            loading="lazy"
            src="../../assets/imgs/home/sentosa_zong_jubu.png"
            alt=""
          />
        </div>
      </div>

      <div class="row-1 flex-row">
        <div class="text flex-column">
          <div class="flex-row">
            <p class="year">{{ this.content.history[2].year }}</p>
          </div>
          <p class="content">{{ this.content.history[2]["content-detail"] }}</p>
        </div>
        <div class="pic">
          <img loading="lazy" src="../../assets/imgs/home/组屋.jpg" alt="" />
        </div>
      </div>

      <div class="row-1 flex-row">
        <div class="text flex-column">
          <div class="flex-row">
            <p class="year">{{ this.content.history[1].year }}</p>
          </div>
          <p class="content">{{ this.content.history[1]["content-detail"] }}</p>
        </div>
        <div class="pic">
          <img
            loading="lazy"
            src="../../assets/imgs/home/singapore-expo.jpg"
            alt=""
          />
        </div>
      </div>
      <div class="row-1 flex-row" style="border-bottom: none">
        <div class="text flex-column">
          <div class="flex-row">
            <p class="year">{{ this.content.history[0].year }}</p>
          </div>
          <p class="content">{{ this.content.history[0]["content-detail"] }}</p>
        </div>
        <div class="pic">
          <img
            loading="lazy"
            src="../../assets/imgs/home/Artboard_1@2x.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <gap :height="0" />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      content: "",
    };
  },
  computed: {
    ...mapState(["lang"]),
  },

  watch: {
    lang(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateDom();
      }
    },
  },

  created() {
    this.content = this.$i18n.t("message");
    this.updateDom();
  },
  methods: {
    updateDom: function () {
      this.content = this.$i18n.t("message");
    },
  },
};
</script>

<style lang="scss" scoped>
.history {
  margin-top: 100vw/1366 * 87.07;
  .gallery {
    padding: 0 100vw/1366 * 145 0 100vw/1366 * 162;
  }

  .gallery-title {
    font-size: 100vw/1366 * 21;
    color: rgba(0, 0, 0, 1);
    font-weight: 700;
    line-height: 25 * 100vw/1366;

    p {
      text-align: center;
    }
  }
  .row-1 {
    min-height: 100vw/1920 * 500;
    //padding-left: 100px;
    padding-bottom: 100px;
    border-bottom: solid rgba(214, 214, 214, 1) 2px;

    .text {
      width: 40%;
      padding: 10% 10% 0 0;

      .year {
        //background-color: green;
        width: fit-content;
        font-size: 100vw/1366 * 35;
        color: rgba(31, 118, 173, 1);
        font-weight: 700;
        line-height: 4rem;
      }
      .title {
        //background-color: red;
        width: fit-content;
        white-space: nowrap;
        margin-left: 100vw/1366 * 15;
        font-size: 100vw/1366 * 25;
        line-height: 4rem;
      }
      .content {
        margin-top: 100vw/1366 * 7;
        font-size: 100vw/1366 * 18;
        line-height: 100/1366 * 25;
        text-align: justify;
      }
    }
    .pic {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      overflow: hidden;
      width: 53%;
      img {
        //height: 100%;
      }
    }
  }
}
</style>